import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import nxtech_logo from "../../pictures/nxtech_logo_black.png";
import nxtech_iamd from "../../pictures/logo_iamd.png";
export default function PDFFile({ pdfData, data, aar, date }) {
  const borderColor = "black";

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },

    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      marginVertical: 15,
    },

    title: {
      fontSize: 24,
      textAlign: "center",
    },

    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },

    avfall: {
      margin: 15,
      fontSize: 18,
      textAlign: "center",
    },

    image1: {
      marginHorizontal: 50,
      width: "200px",
    },

    image2: {
      marginHorizontal: 50,
      width: "130px",
    },

    header: {
      fontSize: 25,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },

    smallHeader: {
      fontSize: 25,
      marginBottom: 16,
      textAlign: "center",
      color: "grey",
    },

    smallestHeader: {
      fontSize: 16,
      marginBottom: 15,
      textAlign: "right",
      color: "grey",
      alignSelf: "flex-end",
    },

    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },

    row: {
      flexDirection: "row",
      borderBottomColor: "black",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      fontSize: 12,
      fontStyle: "bold",
      width: "50%",
    },

    type: {
      width: "50%",
      textAlign: "center",

      borderRightColor: borderColor,
      borderRightWidth: 1,
    },

    antall_kilo: {
      width: "50%",
      textAlign: "center",
    },

    description: {
      width: "50%",
      textAlign: "left",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      paddingLeft: 8,
      borderLeftWidth: 1,
      borderLeftColor: "black",
    },
    kg: {
      width: "50%",
      textAlign: "right",
      paddingRight: 8,
      borderRightWidth: 1,
      borderRightColor: "black",
    },

    wrapper: {
      display: "flex",
      flexDirection: "columns",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.imageContainer}>
          <Image style={styles.image1} src={nxtech_logo} />
          <Image style={styles.image2} src={nxtech_iamd} />
        </View>

        <Text style={styles.header} fixed>
          Innrapportering av Miljøfyrtårndata
        </Text>
        <Text style={styles.smallestHeader} fixed>
          Dato opprettet: {date}
        </Text>

        <Text style={styles.text}>
          Dette er en automatisk generert rapport laget av Bachelorgruppe 16 i
          samarbeid med NxTech. Rapporten er bygd opp slik at den viser full
          oversikt over kildesortert avfall kastet (hittil) i år: {aar}.
        </Text>

        <Text style={styles.smallHeader}>Full oversikt kan sees her:</Text>

        <View style={styles.wrapper}>
          <View style={styles.row}>
            <Text style={styles.type}>Avfallstype</Text>
            <Text style={styles.antall_kilo}>Antall kilo</Text>
          </View>

          {data
            ? data.map((element, index) => {
                return (
                  <View key={index} style={styles.row}>
                    <Text style={styles.description}>{element.type}</Text>
                    <Text style={styles.kg}>{element.kilo.toFixed(2)} kg</Text>
                  </View>
                );
              })
            : ""}
        </View>

        <avfallsData data={data} />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
