import React from 'react'

export default function AndreasText() {
    return (
        <>
            <p>
                Dataingeniørstudent med hjerte for hardware og programmering.
            </p>

            <h3>Favoritt kodespråk</h3>
            <p>Python</p>

            <h3>Hva liker du med studiet?</h3>
            <p>I skolehverdagen liker jeg å være hands-on på hardware og software.</p>


            <h3>Jobbe i team eller alene?</h3>
            <p>Jeg er glad i å jobbe alene, det er i fred og ro jeg føler meg i mitt ess.</p>

            <h3>Hva syntes du om denne bacheloroppgaven?</h3>
            <p>Jeg syntes det er spennende at vi får muligheten til å løse en så kul oppgave med de teknologiene vi vil. Og det at vi da får jobbe hands-on me dhardware og sfotware gjør at jeg liker denne oppgaven veldig godt.</p>

        </>
        
    )
}
