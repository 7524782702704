import limit_decimals from './DecimalLimiter'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React from 'react';
import { useEffect, useState } from 'react';
ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



export default function Diagram_stole({first_dataset, second_dataset, year}) {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        console.log("test", windowSize)
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => { 
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [windowSize]);

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    let labels = first_dataset.map((el, index) => {return el.type})

    const options = {
        responsive: true,
        plugins: {
        legend: {
            position: 'top',
            labels: {
                color: "white",
                font: {
                    size: 20
                }
            }
        },
        // title: {
        //     display: true,
        //     text: 'Kildesortert avfall',
        //     color: "#ffff"
        // },
        },
        scales: {
            y: {
                ticks: {
                    color: "white",
                    font: {
                        size: windowSize.innerWidth > 1600 ? 30 : 20
                    }
                }
            },
            x: {
                ticks: {
                    color: "white",
                    font: {
                        size: windowSize.innerWidth > 1600 ? 30 : 20
                    }
                }
            }
        }
    };
    
    

    const stolpe = {
        labels,
        

        datasets: [
        {
            label: 'Siste 30 dager',
            data: first_dataset.map((el, index) => {return limit_decimals(el.Kilo_totalt)}),
            backgroundColor: '#0D97A4',
            fontColor: "white"


        },

        // second dataset
        second_dataset ? 
        {
            label: `Hittil i ${year}`,
            data: second_dataset.map((el, index) => {return limit_decimals(el.kilo)}),
            backgroundColor: 'rgba(255, 99, 132, 0.5)', //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
            fontColor: "white"

        }
        : null,

        

        ],
    };


    return (
        <Bar options={options} data={stolpe} />
    )

    
};