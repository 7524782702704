import React from 'react'
import axios from 'axios'
import { warning } from '@remix-run/router'
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from 'react-toastify';
import {AWS_URL} from './Variables'


export default function SlettSisteRegistrering({authentication, setIsDeleted, isDeleted}) {
    const [cookies, setCookie] = useCookies();

    function slettMeg() {
        if (window.confirm("Sikkert at du vil slette siste rapportering?")) {
            
                    try {
                        
                        axios.post(`${AWS_URL}/api/oversikt/slett-siste`, {data: {Credentials: "include", mode: 'cors', cookies: cookies}})
                        // axios.post("http://localhost:3005/api/oversikt/slett-siste", {data: {Credentials: "include", mode: 'cors', cookies: cookies}})
                        
                        .then((res) => {
                            if (res.status === 200) {
                                toast.success("Sletting av siste registrerte veiing vellykket!!")
                            }
                                
                            if (res.status === 401) {toast.warn("Not authorized!");console.log("Not authorized!: ", res.status, "data returned: ", res.data)}
                            })
                        .then(() => {setIsDeleted(prev => !prev)})
            
                    } catch (error) {
                        console.log("err ", error)
                    }

        } else {
            return;
        }
    }

    return (
        <div className='slett_meg_knapp'>
            <button onClick={() => {slettMeg()}}>Slett meg</button>
            <ToastContainer/>
        </div>
    )
}

