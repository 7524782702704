import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getCurrentDate } from "../modules/utils/getDateTime";
import SorterListevisning from "../modules/utils/SorterListevisning";
import SlettSisteRegistrering from "../modules/utils/SlettSisteRegistrering";
import limit_decimals from "../modules/utils/DecimalLimiter";
import { ClipLoader } from "react-spinners";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Diagram_stole from "../modules/utils/chart_bar";
import Diagram_doughnut from "../modules/utils/chart_doughnut";
import Diagram_linje from "../modules/utils/chart_line";
import { BsInfoCircle, BsXCircle } from "react-icons/bs";
import {
  Sammenligning_info,
  Totalen_info,
  Sortert_info,
} from "../modules/utils/Sammenligning_info";
import { AWS_URL } from "../modules/utils/Variables";

export default function Home() {
  // states
  const [siste30Dager, setSiste30Dager] = useState([]);
  const [totalenHittil, setTotalenHittil] = useState([]);
  const [totaltEver, setTotaltEver] = useState([]);
  const [sisteRegistrert, setSisteRegistrert] = useState([]);
  const [lastYear, setLastYear] = useState([]);
  const [detteAaretHverMnd, setDetteAaretHverMnd] = useState([]);
  const [forrigeAaretHverMnd, setForrigeAaretHverMnd] = useState([]);

  const [isSortButtonClicket, setSortButtonClicked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [infoSammenligningClicked, setSammenligningInfoClicked] =
    useState(false);
  const [infoSortertClicked, setSortertInfoClicked] = useState(false);
  const [infoTotalenClicked, setTotalenInfoClicked] = useState(false);

  const [isLoading, setLoading] = useState(true);

  //sorteringsvalg
  const [alfaActive, setAlfaActive] = useState(false);
  const [vektActive, setVektActive] = useState(false);
  const [datoActive, setDatoActive] = useState(false);

  let spinnerSize = 70;

  //Auth
  const [authentication, setAuth] = useState(false);

  // navigate
  const navigate = useNavigate();
  let year = new Date().getFullYear();

  // screen width height
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    setAlfaActive(false);
    setVektActive(false);
    setDatoActive(false);
    setAuth(sessionStorage.getItem("Auth") === "True");
    try {
      axios
        .get(`${AWS_URL}/api/oversikt/siste-30-dager`)
        // axios.get("http://localhost:3005/api/oversikt/siste-30-dager")

        .then((res) => {
          let sortedArray = res.data.sort((a, b) => (a.type < b.type ? -1 : 1));

          setSiste30Dager(sortedArray);
        })

        .then(
          // axios.get("http://localhost:3005/api/oversikt/dette-aaret", {params: {year: year}})
          axios
            .get(`${AWS_URL}/api/oversikt/dette-aaret`, {
              params: { year: year },
            })

            .then((res) => {
              let sortedArray = res.data.sort((a, b) =>
                a.type < b.type ? -1 : 1
              );
              setTotalenHittil(sortedArray);
            })
        )

        .then(
          // axios.get("http://localhost:3005/api/oversikt/totalen-sortert")
          axios
            .get(`${AWS_URL}/api/oversikt/totalen-sortert`)

            .then((res) => {
              let sortedArray = res.data.sort((a, b) =>
                a.type < b.type ? -1 : 1
              );
              setTotaltEver(sortedArray);
            })
        )

        .then(() => {
          axios
            .get(`${AWS_URL}/api/oversikt/siste_registrering`, {
              params: { year: year },
            })
            // axios.get("http://localhost:3005/api/oversikt/siste_registrering", {params: {year: year}})

            .then((res) => {
              // Last inserted
              let lastInsertedArray = res.data.sort((a, b) =>
                a.dato < b.dato ? 1 : -1
              );
              let get_last_inserted = lastInsertedArray[0];
              let newDate = new Date(get_last_inserted.dato).toLocaleDateString(
                "no-NO",
                { year: "numeric", month: "2-digit", day: "2-digit" }
              );
              get_last_inserted.dato = newDate;
              setSisteRegistrert(get_last_inserted);
            });
        })

        .then(() => {
          axios
            .get(`${AWS_URL}/api/oversikt/forrige-aar`, {
              params: { year: year - 1 },
            })
            // axios.get("http://localhost:3005/api/oversikt/forrige-aar", {params: {year: year - 1}})

            .then((res) => {
              // Last inserted
              let sortedArray = res.data.sort((a, b) =>
                a.type < b.type ? -1 : 1
              );
              setLastYear(sortedArray);
            });
        })

        .then(() => {
          axios
            .get(`${AWS_URL}/api/oversikt/dette-aaret-hver-mnd`, {
              params: { year: year },
            })
            // axios.get("http://localhost:3005/api/oversikt/dette-aaret-hver-mnd", {params: {year: year}})

            .then((res) => {
              // Last inserted
              setDetteAaretHverMnd(res.data);
            });
        })

        .then(() => {
          axios
            .get(`${AWS_URL}/api/oversikt/forrige-aaret-hver-mnd`, {
              params: { year: year },
            })
            // axios.get("http://localhost:3005/api/oversikt/forrige-aaret-hver-mnd", {params: {year: year}})

            .then((res) => {
              // Last inserted
              setForrigeAaretHverMnd(res.data);
            });
        })

        .then(() => setLoading(false))

        .catch((err) => {
          setLoading(false);
          console.log("Error: ", err);
        });
    } catch (error) {
      console.log("Error while fetching from API", error);
    }
  }, [isDeleted]);

  function goToRapporteringer() {
    navigate("/rapporteringer");
  }

  return (
    <>
      <span className="comingSoon"></span>
      <div className="home">
        <h4 className="show_datetime">{getCurrentDate()}</h4>

        <SorterListevisning
          alfaActive={alfaActive}
          setAlfaActive={setAlfaActive}
          vektActive={vektActive}
          setVektActive={setVektActive}
          datoActive={datoActive}
          setDatoActive={setDatoActive}
          isSortButtonClicket={isSortButtonClicket}
          setSortButtonClicked={setSortButtonClicked}
          siste30Dager={siste30Dager}
          setSiste30Dager={setSiste30Dager}
          totalenHittil={totalenHittil}
          setTotalenHittil={setTotalenHittil}
        />

        <main className="målinger">
          {isLoading ? (
            <span style={{ marginTop: "3rem" }}>
              <ClipLoader size={spinnerSize} color="#1ed9e9" />
            </span>
          ) : (
            <div className="oversikt_vekt">
              <div style={{ zIndex: "100" }}></div>

              <h2>Siste 30 dager</h2>
              <ul>
                {siste30Dager.map((element, index) => (
                  <li key={index}>
                    <h3>{element.type}</h3>
                    <h3>{limit_decimals(element.Kilo_totalt)} kg</h3>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {isLoading ? (
            <span style={{ marginTop: "3rem" }}>
              <ClipLoader size={spinnerSize} color="#1ed9e9" />
            </span>
          ) : (
            <div className="oversikt_vekt">
              <h2>Totalt i {new Date().getFullYear()}</h2>
              <ul>
                {totalenHittil.map((element, index) => (
                  <li key={index}>
                    <h3>{element.type}</h3>
                    <h3>{limit_decimals(element.kilo)} kg</h3>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </main>

        <article className="diagram_siste_30_dager">
          {siste30Dager ? (
            <>
              <h2>
                Kildesortert avfall{" "}
                <button
                  onClick={() => setSortertInfoClicked((prev) => !prev)}
                  className="info_button"
                >
                  {infoSortertClicked ? (
                    <>
                      <BsXCircle color="#da3733" size={22} /> <Sortert_info />
                    </>
                  ) : (
                    <BsInfoCircle size={22} />
                  )}
                </button>
              </h2>
              {isLoading ? (
                <span style={{ marginTop: "3rem" }}>
                  <ClipLoader size={90} color="#1ed9e9" />
                </span>
              ) : (
                <Diagram_stole
                  year={year}
                  first_dataset={siste30Dager}
                  second_dataset={totalenHittil}
                />
              )}
            </>
          ) : null}
        </article>

        <article className="diagram_siste_30_dager">
          {totalenHittil && windowSize.innerWidth > 950 ? (
            <>
              <h2>
                Sammenligning{" "}
                <button
                  onClick={() => setSammenligningInfoClicked((prev) => !prev)}
                  className="info_button"
                >
                  {infoSammenligningClicked ? (
                    <>
                      <BsXCircle color="#da3733" size={22} />{" "}
                      <Sammenligning_info />
                    </>
                  ) : (
                    <BsInfoCircle size={22} />
                  )}
                </button>
              </h2>
              {isLoading ? (
                <span style={{ marginTop: "3rem" }}>
                  <ClipLoader size={spinnerSize} color="#1ed9e9" />
                </span>
              ) : (
                <Diagram_linje
                  year={year}
                  first_dataset={detteAaretHverMnd}
                  second_dataset={forrigeAaretHverMnd}
                />
              )}
            </>
          ) : null}
        </article>

        {sisteRegistrert != 0 ? (
          <div className="ekstra_features">
            <h2>Siste registrerte avfall</h2>
            {isLoading ? (
              <span style={{ marginTop: "3rem" }}>
                <ClipLoader size={spinnerSize} color="#1ed9e9" />
              </span>
            ) : (
              <div className="siste_registrerte_feature">
                <table>
                  <tr>
                    <th>Type</th>
                    <th>Kilo</th>
                    <th>Dato</th>
                    {authentication ? <th>Slett</th> : null}
                  </tr>
                  <tr key={"heyheyhallo"}>
                    {sisteRegistrert ? <td>{sisteRegistrert.type}</td> : null}
                    {/* <span style={{marginRight: "5px"}}></span> { */}
                    {sisteRegistrert ? (
                      <td>
                        {sisteRegistrert.kilo}{" "}
                        <span className="siste_registrering_slette_farge">
                          kg
                        </span>
                      </td>
                    ) : null}
                    <td>{sisteRegistrert.dato}</td>
                    {authentication ? (
                      <td>
                        <SlettSisteRegistrering
                          authentication={authentication}
                          isDeleted={isDeleted}
                          setIsDeleted={setIsDeleted}
                        />
                      </td>
                    ) : null}
                  </tr>
                </table>
              </div>
            )}

            {authentication ? (
              <>
                <h3 style={{ marginTop: "1rem" }}>
                  Gå til alle registreringer
                </h3>
                <button
                  onClick={() => {
                    goToRapporteringer();
                  }}
                  className="button_rapporteringer"
                >
                  Registreringer
                </button>
              </>
            ) : null}
          </div>
        ) : null}

        <section className="målinger">
          {isLoading ? (
            <span style={{ marginTop: "3rem" }}>
              <ClipLoader size={spinnerSize} color="#1ed9e9" />
            </span>
          ) : (
            <div className="oversikt_vekt">
              <h2>Totalt</h2>
              <ul>
                {totaltEver.map((element, index) => (
                  <li key={index}>
                    <h3>{element.type}</h3>
                    <h3>{limit_decimals(element.Kilo_totalt)} kg</h3>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </section>

        {isLoading ? (
          <span style={{ marginTop: "3rem" }}>
            <ClipLoader size={spinnerSize} color="#1ed9e9" />
          </span>
        ) : (
          <article className="diagram_doughnut">
            <h2 className="totalt_siden_2023">
              Totalt fra 2023{" "}
              <button
                onClick={() => setTotalenInfoClicked((prev) => !prev)}
                className="info_button"
              >
                {infoTotalenClicked ? (
                  <>
                    <BsXCircle color="#da3733" size={22} /> <Totalen_info />
                  </>
                ) : (
                  <BsInfoCircle size={22} />
                )}
              </button>
            </h2>
            <Diagram_doughnut incoming_data={totaltEver} />
          </article>
        )}
      </div>
    </>
  );
}
