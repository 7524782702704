
import StianText from './stian'
import AndreasText from './andreas'
import SimenText from './simen'


const init =     [
        {
        name: "Stian Larsen",
        first_name: "Stian",
        age: 26,
        info: "Dataingeniør | Samboer",
        funfact: "Musikkproduksjon & fullstack",
        id: 0,
        description: <StianText/>,
        img: "https://nxtech.s3.eu-north-1.amazonaws.com/pictures/stian.png"
    }, 
    {
        name: "Andreas Mathisen",
        first_name: "Andreas",
        age: 22,
        info: "Dataingeniør",
        funfact: "Hardware",
        id: 1,
        description: <AndreasText/>,
        img: "https://nxtech.s3.eu-north-1.amazonaws.com/pictures/andreas.png"
    },
    
    {
        name: "Simen Kjærstad",
        first_name: "Simen",
        age: 25,
        info: "Dataingeniør",
        funfact: "IT DUDEN",
        id: 2,
        description: <SimenText/>,
        img: "https://nxtech.s3.eu-north-1.amazonaws.com/pictures/simen.png"
    }
]

export default init;