import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { useCookies } from "react-cookie";
import { AWS_URL } from "../modules/utils/Variables";

// <RingLoader color="#36d7b7" />
let init = {
  username: null,
  password: null,
};
export default function LoggInn() {
  const [data, setData] = useState(init);
  const [checkRes, setRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies();
  useEffect(() => {
    console.log("Cookies: ", cookies);
  }, [cookies]);

  let navigate = useNavigate();

  const handlechange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  async function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    const { username, password } = data;
    console.log("user: ", username, password);

    if (username != "nxtech" && username != "stian") {
      toast.error("Ingen adgang med dette brukernavnet!");
      return setLoading(false);
    } else {
      localStorage.setItem("bruker", username);
      try {
        // axios.post("http://localhost:3005/api/checkUser", {data: {username: username, password, credentials: "include"}})
        axios
          .post(`${AWS_URL}/api/checkUser`, {
            data: { username: username, password, credentials: "include" },
          })

          .then((res) => {
            console.log("Status: ", res.status);
            if (res.status === 200) {
              setCookie("isAuthenticated", true, {
                maxAge: 7400,
                httpOnly: false,
                secure: false,
              });
              setCookie("user", username, {
                maxAge: 7400,
                httpOnly: false,
                secure: false,
              });
            }
            setRes(res.data);
            return res.data;
          })

          .then((data) => {
            if (data == 0) {
              toast.error("Feil passord!");
              setLoading(false);
            }

            if (data === 1) {
              toast.success("Godkjent");
              sessionStorage.setItem("Auth", "True");
              setTimeout(() => {
                setLoading(false);
                navigate("/rapport");
              }, 1000);
            }
          });
      } catch (err) {
        console.log("Hello...error..", err);
      }
    }
  }

  return (
    <div className="logg_inn_container">
      {loading ? (
        <span style={{ marginTop: "3rem" }}>
          <ClipLoader color="#1ed9e9" />
        </span>
      ) : (
        <form
          className="logg_inn_form"
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <label>Brukernavn</label>
          <input
            autoFocus
            autoComplete="true"
            onChange={handlechange}
            type="username"
            name="username"
          />

          <label>Passord</label>
          <input
            autoComplete="true"
            onChange={handlechange}
            type="password"
            name="password"
          />

          <button type="submit">Logg inn</button>
        </form>
      )}

      <ToastContainer />
    </div>
  );
}
