import React from 'react'
import { Link, NavLink } from "react-router-dom";
import logo from "../pictures/nxtech_logo.png"
import { useState, useEffect } from 'react';
import NavDropdown from '../modules/NavDropdown';
import Navlinks from '../modules/NavigationLinks';
export default function NavBar() {

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [clicked, setClicked] = useState(false);


    // Check the window/screen size of the browser. Based on this the Title of the navbar will change.
    // if its under 750px, its suspected youre on a mobile device, and the title will become shorter.
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => { 
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [windowSize]);

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }




    return (
        <nav className={clicked && windowSize.innerWidth < 951 ? "clickedNavbar": 'navbar'}>
            <section className='logo' style={{display: "flex", alignItems: "center"}}>
                <Link to="/">                
                    <img style={{width: "200px"}} src={logo} alt="LOGO" />
                </Link>

            </section>

            {/* <header style={{fontSize: "2rem"}}>{ windowSize.innerWidth > 850 ?  "Bacheloroppgave" : "" }</header> */}

            {windowSize.innerWidth > 950 ? (
                <Navlinks/>
            ) : <NavDropdown setClicked={setClicked} clicked={clicked}/>}

        </nav>
    )
}
