import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { SlClose } from "react-icons/sl"

export default function Person({init}) {
    const [data, setData] = useState([]);
    let { user } = useParams();
    

    useEffect(() => {
        if (user === "Stian") {
            setData(init[0])
        }
        if (user === "Andreas") {
            setData(init[1])}
        if (user === "Simen") {
            setData(init[2])}
    }, [])

    return (
        <div className='person-side'>
            <img src={data.img} alt={`Profile picture of ${data.name}`} />

            <section className='person-details'>
                <h1>{data.name}</h1>
                <p>{data.description}</p>
            </section>

            <Link className='closeButton' to="/om-oss"><SlClose size={50}/></Link>
        </div>
    )
}
