import React from 'react'

export default function SimenText() {
    return (
        <>
            <p>
                Dataingeniørstudent med lidenskap for problemløsing med kode.
            </p>

            <h3>Favoritt kodespråk</h3>
            <p>Python ?¿</p>

            <h3>Hva liker du med studiet?</h3>
            <p>I skolehverdagen liker jeg å møte gruppa jeg jobber med og jobbe med prosjekter sammen med de. Det hjelper å finne noen å jobbe med for å øke motivasjonen til å komme seg på skolen.</p>


            <h3>Jobbe i team eller alene?</h3>
            <p>På større prosjekter liker jeg å jobbe i Team, med ved mindre oppgaver som feks innleveringer liker jeg å jobbe alene.</p>

            <h3>Hva syntes du om denne bacheloroppgaven?</h3>
            <p>Jeg syntes det er spennende at vi alle får jobbe sammen om et prosjekt hvor alle kan bidra med sitt. Stian er god på frontend og skytjenester, imens jeg og Andreas kan jobbe med hardware og sfotware som vi liker. Så det er fint at alle kan bidra og fortsatt ha det gøy.</p>

        </>
    )
}
