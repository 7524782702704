import React from "react";

export function Sammenligning_info() {
  return (
    <div className="Sammenligning_info">
      <p>
        Avfallstypene kan trykkes på for å fjerne de som er urelevante. Feks:
        sammenligne Restavfall 2023-2022 ? trykk på alle de andre for å "fjerne"
        de.
      </p>
    </div>
  );
}

export function Totalen_info() {
  return (
    <div className="Sammenligning_info">
      <p>
        Totalen viser alle data innsamlet fra oppstarten av systemet. Feks: Hvis
        systemet blir tatt i bruk 2023, vil man i 2025 kunne se en total
        oversikt fra 2023 til 2025 o.l.
      </p>
    </div>
  );
}

export function Sortert_info() {
  return (
    <div className="Sammenligning_info">
      <p>
        Dette er en oversikt som sammenligner kildesortert avfall de siste 30
        dager med helheten av året.
      </p>
    </div>
  );
}
