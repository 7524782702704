import React from 'react'
import {  useNavigate } from 'react-router-dom'

export default function LoggInn() {
    const navigate = useNavigate();
    return (
    <div>
        <button className='logg_inn_button' onClick={() => {navigate("/logg-inn")}}>Logg inn</button>
    </div>
    )
}
