import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";

export default function SorteringsValg({
  siste30Dager,
  setSiste30Dager,
  totalenHittil,
  setTotalenHittil,
  setSortButtonClicked,
  isSortButtonClicket,
  alfaActive,
  setAlfaActive,
  setDatoActive,
  datoActive,
  vektActive,
  setVektActive,
}) {
  const [obj, setObj] = useState({
    active: null,
    objects: [
      {
        sorteringsvalg: "Alfabetisk",
        id: 0,
        sortert: "a - z",
      },
      {
        sorteringsvalg: "Etter vekt",
        id: 1,
        sortert: "tyngst",
      },
      {
        sorteringsvalg: "Etter dato",
        id: 2,
        sortert: "nyest",
      },
    ],
  });

  useEffect(() => {
    if (obj.active === obj.objects[0]) {
      setDatoActive(false);
      setVektActive(false);
    }
    if (obj.active === obj.objects[1]) {
      setDatoActive(false);
      setAlfaActive(false);
    }
    if (obj.active === obj.objects[2]) {
      setAlfaActive(false);
      setVektActive(false);
    }
  }, [alfaActive, datoActive, vektActive]);

  function handleSorteringsKlikk(element, id) {
    setObj({ ...obj, active: obj.objects[id] });

    if (element.sorteringsvalg === "Alfabetisk") {
      if (!alfaActive) {
        setSiste30Dager((data) => {
          let arrayToSort = [...siste30Dager];
          arrayToSort.sort((a, b) => (a.type < b.type ? -1 : 1));
          return arrayToSort;
        });

        setTotalenHittil((data) => {
          let arrayToSort = [...totalenHittil];
          arrayToSort.sort((a, b) => (a.type < b.type ? -1 : 1));
          setAlfaActive((prev) => !prev);
          return arrayToSort;
        });
      } else {
        setSiste30Dager((data) => {
          let arrayToSort = [...siste30Dager];
          arrayToSort.sort((a, b) => (a.type > b.type ? -1 : 1));
          return arrayToSort;
        });

        setTotalenHittil((data) => {
          let arrayToSort = [...totalenHittil];
          arrayToSort.sort((a, b) => (a.type > b.type ? -1 : 1));
          setAlfaActive((prev) => !prev);
          return arrayToSort;
        });
      }
    }

    if (element.sorteringsvalg === "Etter vekt") {
      console.log("etter vekt..", element.sorteringsvalg);

      if (!vektActive) {
        setSiste30Dager(() => {
          let arrayToSort = [...siste30Dager];
          arrayToSort.sort((a, b) => (a.Kilo_totalt < b.Kilo_totalt ? 1 : -1));
          return arrayToSort;
        });

        setTotalenHittil(() => {
          let arrayToSort = [...totalenHittil];
          arrayToSort.sort((a, b) => (a.kilo < b.kilo ? 1 : -1));
          setVektActive((prev) => !prev);
          return arrayToSort;
        });
      } else {
        setSiste30Dager(() => {
          let arrayToSort = [...siste30Dager];
          arrayToSort.sort((a, b) => (a.Kilo_totalt > b.Kilo_totalt ? 1 : -1));
          return arrayToSort;
        });

        setTotalenHittil(() => {
          let arrayToSort = [...totalenHittil];
          arrayToSort.sort((a, b) => (a.kilo > b.kilo ? 1 : -1));
          setVektActive((prev) => !prev);
          return arrayToSort;
        });
      }
    }
    if (element.sorteringsvalg === "Etter dato") {
      if (!datoActive) {
        setSiste30Dager(() => {
          let arrayToSort = [...siste30Dager];
          arrayToSort.sort((a, b) => (a.dato < b.dato ? 1 : -1));
          return arrayToSort;
        });

        setTotalenHittil(() => {
          let arrayToSort = [...totalenHittil];
          arrayToSort.sort((a, b) => (a.dato < b.dato ? 1 : -1));
          setDatoActive((prev) => !prev);
          return arrayToSort;
        });
      } else {
        setSiste30Dager(() => {
          let arrayToSort = [...siste30Dager];
          console.log("Sort før: ", arrayToSort);
          arrayToSort.sort((a, b) => (a.dato > b.dato ? 1 : -1));
          console.log("Sort etter: ", arrayToSort);

          return arrayToSort;
        });

        setTotalenHittil(() => {
          let arrayToSort = [...totalenHittil];
          arrayToSort.sort((a, b) => (a.dato > b.dato ? 1 : -1));
          setDatoActive((prev) => !prev);
          return arrayToSort;
        });
      }
    }
  }

  console.log("obj: ", obj.active);
  return (
    <div className="sorteringsValg">
      <ul>
        {obj.objects.map((element, index) => (
          <li
            className={
              obj.active == obj.objects[index] ? "activeSorteringsValg" : ""
            }
            onClick={() => {
              handleSorteringsKlikk(element, index);
            }}
            key={index}
            name={element.id}
          >
            {element.sorteringsvalg}
            {(obj.active === obj.objects[index] && datoActive === true) ||
            (obj.active === obj.objects[index] && vektActive === true) ||
            (obj.active === obj.objects[index] && alfaActive === true) ? (
              <div className="sortert">{element.sortert}</div>
            ) : null}
          </li>
        ))}

        {isSortButtonClicket === true ? (
          <li
            className="close_sorted_options"
            onClick={() => {
              setSortButtonClicked((prev) => !prev);
            }}
          >
            {<AiFillCloseCircle />}
          </li>
        ) : null}
      </ul>
    </div>
  );
}
