import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AWS_URL } from "../modules/utils/Variables";

export default function OmOss({ init }) {
  const [group, setGroup] = useState(init);
  const [members, setMembers] = useState([]);

  const navigate = useNavigate();

  function handleClick(name, id) {
    navigate(`/om-oss/${name}`);
  }

  useEffect(() => {
    axios
      .get(`${AWS_URL}/api/om-oss`)

      .then((res) => {
        setMembers(res.data);
        console.log("res", res.data);
      });
  }, []);

  useEffect(() => {
    console.log("members: ", members);
  }, [members]);

  return (
    <div className="omoss">
      <h1>Om oss</h1>

      <div className="cards">
        {members.map((element, index) => {
          return (
            <div
              key={index}
              className="card"
              onClick={() => {
                handleClick(element.first_name, index);
              }}
            >
              <img className="profilePicture" src={element.imageURL} />

              <div className="card-info">
                <h2>{element.first_name}</h2>
                <h4>{element.age}</h4>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
