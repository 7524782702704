import React from 'react'
import SorteringsValg from '../utils/SorteringsValg'

export default function SorterListevisning({siste30Dager, setSiste30Dager, totalenHittil, setTotalenHittil,isSortButtonClicket, setSortButtonClicked, alfaActive, setAlfaActive, datoActive, setDatoActive, vektActive, setVektActive}) {

    return (
        <div className='sorter_listevisning_container'>
            {isSortButtonClicket ? 
                                    <SorteringsValg
                                    alfaActive={alfaActive} setAlfaActive={setAlfaActive}
                                    vektActive={vektActive} setVektActive={setVektActive}
                                    datoActive={datoActive} setDatoActive={setDatoActive}
                                    siste30Dager={siste30Dager} setSiste30Dager={setSiste30Dager}
                                    totalenHittil={totalenHittil} setTotalenHittil={setTotalenHittil}
                                    setSortButtonClicked={setSortButtonClicked} isSortButtonClicket={isSortButtonClicket}


            /> : (<div className='sorteringsKnapp'><h2  onClick={() => {setSortButtonClicked(prev => !prev);}}>Sortering</h2></div>)}
        </div>
    )
}
