import limit_decimals from "./DecimalLimiter";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import React from "react";
import { useState, useEffect } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Diagram_linje({ first_dataset, second_dataset, year }) {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  let labels = [
    "Januar",
    "Februar",
    "Mars",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  // Dette året
  let restavfall_array = [];
  let plastikk_array = [];
  let papir_array = [];
  let elektronikk_array = [];
  let batterier_array = [];
  let matavfall_array = [];

  // Forrige året
  let restavfall_array_forrige_aar = [];
  let plastikk_array_forrige_aar = [];
  let papir_array_forrige_aar = [];
  let elektronikk_array_forrige_aar = [];
  let batterier_array_forrige_aar = [];
  let matavfall_array_forrige_aar = [];

  let restavfall_dette_aaret = second_dataset.map((element) => {
    if (element.type === "Restavfall") {
      restavfall_array_forrige_aar.push(element);
    }
    if (element.type === "Papir") {
      plastikk_array_forrige_aar.push(element);
    }
    if (element.type === "Plastikk") {
      papir_array_forrige_aar.push(element);
    }
    if (element.type === "Elektronikk") {
      elektronikk_array_forrige_aar.push(element);
    }
    if (element.type === "Batterier") {
      batterier_array_forrige_aar.push(element);
    }
    if (element.type === "Matavfall") {
      matavfall_array_forrige_aar.push(element);
    }
  });

  let restavfall_forrige_aar = first_dataset.map((element) => {
    if (element.type === "Restavfall") {
      restavfall_array.push(element);
    }
    if (element.type === "Papir") {
      papir_array.push(element);
    }
    if (element.type === "Plastikk") {
      plastikk_array.push(element);
    }
    if (element.type === "Elektronikk") {
      elektronikk_array.push(element);
    }
    if (element.type === "Batterier") {
      batterier_array.push(element);
    }
    if (element.type === "Matavfall") {
      matavfall_array.push(element);
    }
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "white",
          font: {
            size: 20,
          },
        },
      },

      title: {
        display: true,
        text: `Kildesortert avfall fra ${year} - ${year - 1}`,
        color: "#ffff",
        font: {
          size: 20,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          color: "white",
          font: {
            size: windowSize.innerWidth > 2000 ? 30 : 20,
          },
        },
      },
      x: {
        ticks: {
          color: "white",
          font: {
            size: windowSize.innerWidth > 2000 ? 30 : 20,
          },
        },
      },
    },
  };

  const stolpe = {
    labels,

    datasets: [
      //  1
      {
        label: `Restavfall ${year}`,
        data: restavfall_array.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "#0D97A4",
        fontColor: "white",
        borderColor: "#0D97A4",
      },

      // 2
      {
        label: `Plastikk ${year}`,
        data: plastikk_array.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(255, 99, 132, 0.5)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(255, 99, 132, 0.5)",
      },

      // 3
      {
        label: `Papir ${year}`,
        data: papir_array.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(255, 206, 86, 0.5)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(255, 206, 86, 0.5)",
      },
      // 4
      {
        label: `Elektronikk ${year}`,
        data: elektronikk_array.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(153, 102, 255, 0.5)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(153, 102, 255, 0.5)",
      },

      // 5
      {
        label: `Batterier ${year}`,
        data: batterier_array.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(50, 110, 200, 0.8)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(50, 110, 200, 0.8)",
      },

      // 6
      {
        label: `Matavfall ${year}`,
        data: matavfall_array.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(200, 255, 64, 0.5)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(200, 255, 64, 0.5)",
      },

      // Forrige år !!!!!!
      //  1
      {
        label: `Restavfall ${year - 1}`,
        data: restavfall_array_forrige_aar.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(200, 255, 64, 0.5)",
        fontColor: "white",
        borderColor: "rgba(200, 255, 64, 0.5)",
      },

      // 2
      {
        label: `Plastikk ${year - 1}`,
        data: plastikk_array_forrige_aar.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(50, 110, 200, 0.8)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(50, 110, 200, 0.8)",
      },

      // 3
      {
        label: `Papir ${year - 1}`,
        data: papir_array_forrige_aar.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(255, 206, 86, 0.5)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(255, 206, 86, 0.5)",
      },
      // 4
      {
        label: `Elektronikk ${year - 1}`,
        data: elektronikk_array_forrige_aar.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(255, 206, 86, 0.5)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(255, 206, 86, 0.5)",
      },

      // 5
      {
        label: `Batterier ${year - 1}`,
        data: batterier_array_forrige_aar.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "rgba(255, 99, 132, 0.5)", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "rgba(255, 99, 132, 0.5)",
      },

      // 6
      {
        label: `Matavfall ${year - 1}`,
        data: matavfall_array_forrige_aar.map((el, index) => {
          return limit_decimals(el.kilo);
        }),
        backgroundColor: "#0D97A4", //rosa: 'rgba(255, 99, 132, 0.5)'  //blå: 'rgba(53, 162, 235, 0.5)'
        fontColor: "white",
        borderColor: "#0D97A4",
      },
    ],
  };

  return <Line options={options} data={stolpe} />;
}
