import axios from "axios";
import React, { useEffect, useState } from "react";
import PDFFile from "../modules/utils/PDFrender";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { getCurrentDateOnly } from "../modules/utils/getDateTime";
import { AWS_URL } from "../modules/utils/Variables";

export default function Rapport() {
  const authenticated = sessionStorage.getItem("Auth") === "True";
  const [data, setData] = useState(null);
  const [pdfData, setPDFdata] = useState(null);
  const [aar, setAar] = useState(null);
  const [loading, setLoading] = useState(false);
  const date = getCurrentDateOnly();
  const [user, setUser] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let user = localStorage.getItem("bruker");
    let string = user.charAt(0).toUpperCase() + user.slice(1);
    setUser(string);
  }, []);

  function handleGetReport(event) {
    setLoading(true);
    event.preventDefault();
    let obj = { year: parseInt(event.target.name) };
    console.log("aar", obj.year);
    setAar(obj.year);
    try {
      axios
        .get(`${AWS_URL}/api/oversikt/dette-aaret`, { params: obj })

        .then((res) => {
          let result = res.data;
          console.log("res fra backend", result);
          let sortedArray = result.sort((a, b) => (a.type < b.type ? -1 : 1));
          let pdfArray = sortedArray.map((element) => {
            <Text>{element.type}</Text>;
          });
          setPDFdata(pdfArray);
          setData(sortedArray);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log("Error from useEffect: ", error);
    }
  }

  function goToRapporteringer() {
    navigate("/rapporteringer");
  }
  return (
    <div className="rapport_container">
      {authenticated ? (
        <>
          {loading ? (
            <span style={{ marginTop: "3rem" }}>
              <ClipLoader color="#1ed9e9" />
            </span>
          ) : (
            <>
              <h1>{`Velkommen ${user}`}!</h1>
              <h2 style={{ marginTop: "1rem" }}>
                Se eller slett registreringer
              </h2>
              <button
                onClick={() => {
                  goToRapporteringer();
                }}
                className="button_rapporteringer"
              >
                Registreringer
              </button>
              <h1>Last ned rapport</h1>
              <h3 style={{ width: "350px" }}>
                Velg hvilket år du vil laste ned rapport for ved å trykke på
                ønsket knapp.
              </h3>

              <div className="buttons">
                <button
                  name="2023"
                  onClick={(event) => {
                    handleGetReport(event);
                  }}
                >
                  Last ned rapport for 2023
                </button>
                <button
                  name="2022"
                  onClick={(event) => {
                    handleGetReport(event);
                  }}
                >
                  Last ned rapport for 2022
                </button>
              </div>

              {data != null ? (
                <PDFDownloadLink
                  document={
                    <PDFFile
                      date={date}
                      pdfData={pdfData}
                      data={data}
                      aar={aar}
                    />
                  }
                  fileName={`Innrapportering av Miljøfyrtårndata ${aar}`}
                >
                  {({ loading }) =>
                    loading ? (
                      <button>Laster inn dokument...</button>
                    ) : (
                      <button className="download_PDF">Download</button>
                    )
                  }
                </PDFDownloadLink>
              ) : null}
            </>
          )}
        </>
      ) : (
        <h1>Ingen adgang</h1>
      )}
    </div>
  );
}
