import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { SlClose } from "react-icons/sl";
import LoggInnButton from "../modules/LoggInnButton";

export default function Navlinks({ setClicked, clicked }) {
  const authentication = sessionStorage.getItem("Auth") === "True";
  let activeClassName = "activeLinkUnderline";

  let navigate = useNavigate();

  return (
    <div className="links">
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            HJEM
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/prosjektet"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            PROSJEKTET
          </NavLink>
        </li>

        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
            to="/om-oss"
          >
            OM OSS
          </NavLink>
        </li>

        <li>
          <NavLink
            to="https://www.nxtech.no/"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            NxTech
          </NavLink>
        </li>

        <li>
          {" "}
          {authentication ? (
            <button
              className="logg_inn_button"
              onClick={() => {
                navigate("/rapport");
              }}
            >
              RAPPORT
            </button>
          ) : (
            <LoggInnButton />
          )}
        </li>

        <li
          onClick={() => {
            setClicked(!clicked);
          }}
        >
          {clicked ? <SlClose size={30} /> : null}
        </li>
      </ul>
    </div>
  );
}
