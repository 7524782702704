import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function NoSide404() {
    const navigate = useNavigate();
  return (
    <div className='noside_404'>
      <h1>Dette siden eksisterer ikke!</h1>

      <p>Prøv igjen <a onClick={() => {navigate("/")}}>her...</a></p>
    </div>
  )
}
