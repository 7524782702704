export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `Dagens dato: ${separator}${date}.${separator}${month<10?`0${month}`:`${month}`}.${year}`
    }

export function getCurrentDateOnly(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${separator}${date}.${separator}${month<10?`0${month}`:`${month}`}.${year}`
    }