export default function Prosjektet_initial({logo}) {
    return (
        <article>
            <h2>Hva går prosjektet ut på?</h2>
            <p>
            Innrapportering av Miljøfyrtårndata er en bacheloroppgave som fremmer IoT konsepter, 
            og tar i bruk firmware, hardware, software og skytjenester for å løse oppgaven.
            </p>

            <p>
            Miljøfyrtårn er en sertifiseringsordning for bedrifter og privatpersoner som har lyst til å fokusere på
            miljøet. Via ordningen får man tilgang til et dashboard hvor man enkelt plotter inn sitt kildesorterte avfallet for året gått.
            </p>

            <p>

            </p>
        </article>
        
    )
}