import React, { useState } from 'react'
import { GiHamburgerMenu } from "react-icons/gi";
import Navlinks from './NavigationLinks';
export default function NavDropdown({setClicked, clicked}) {
    return (
        <div className={clicked ? "dropdownclicked": 'dropdown'} onClick={() => {setClicked(!clicked)}}>
            {clicked ? <Navlinks setClicked={setClicked} clicked={clicked}/> : <GiHamburgerMenu size={40}/>}
        </div>
    )
}
