import './App.css';
import NavBar from './components/pages/NavBar';
import { Link, BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './components/pages/Home';
import Layout from './components/pages/Layout';
import OmOss from './components/pages/OmOss';
import OmOssOutlet from './components/pages/OmOssOutlet';
import Person from './components/pages/Person';
import Prosjektet from './components/pages/Prosjektet';
import init from './components/info_person/inititalValueOfTheGroup';
import LoggInn from './components/pages/LoggInn';
import { useState } from 'react';
import Rapport from './components/pages/Rapport';
import Rapporteringer from './components/pages/Rapporteringer';
import NoSide404 from './components/pages/NoSide404';

function App() {
  return (
    <div className="App">
      <NavBar></NavBar>

      <Routes>
          <Route path="/" element={<Layout/>}>
              <Route index element={<Home/>}></Route>
              <Route path="om-oss" element={<OmOssOutlet/>}>

                  <Route index element={<OmOss init={init}/>}></Route>
                  <Route path='/om-oss/:user' element={<Person init={init}/>}></Route>
              </Route>

              <Route path="logg-inn" element={<LoggInn/>}/>
              <Route path="prosjektet" element={<Prosjektet/>}/>
              <Route path="rapport" element={<Rapport/>}/>
              <Route path="rapporteringer" element={<Rapporteringer/>}/>
              <Route path="*" element={<NoSide404/>}/>

          </Route>
      </Routes>
    </div>
  );
}

export default App;
