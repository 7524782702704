import React, { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { BsFilterCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AWS_URL } from "../modules/utils/Variables";

export default function Rapporteringer() {
  const [cookies, setCookie] = useCookies();
  const authenticated = sessionStorage.getItem("Auth") === "True";
  const [data, setData] = useState([]);
  const [activeDate, setActiveDate] = useState(false);
  const [activeVekt, setActiveVekt] = useState(false);
  const [activeType, setActiveType] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      toast.warn(
        "Du er ikke logget inn, og har ingen tilgang på denne siden! For å få tilgang må du logge inn."
      );
      setTimeout(() => {
        navigate("/");
      }, 6000);
    } else {
      try {
        // axios.get("http://localhost:3005/api/rapporteringer")
        axios
          .get(`${AWS_URL}/api/rapporteringer`)

          .then((res) => {
            console.log(res.data);
            let arrayToSort = res.data;
            arrayToSort.sort((a, b) => (a.dato > b.dato ? -1 : 1));
            setData(arrayToSort);
          })

          .then(() => {
            setLoading(false);
          });
      } catch (error) {
        console.log("Error from useEffect: ", error);
      }
    }
  }, [isDeleted]);

  function sorterDato() {
    if (activeDate) {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.dato > b.dato ? -1 : 1));
      setData(arrayToSort);
      setActiveDate((prev) => !prev);
    } else {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.dato < b.dato ? -1 : 1));
      setData(arrayToSort);
      setActiveDate((prev) => !prev);
    }
  }
  function sorterVekt() {
    if (activeVekt) {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.kilo < b.kilo ? -1 : 1));
      setData(arrayToSort);
      setActiveVekt((prev) => !prev);
    } else {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.kilo > b.kilo ? -1 : 1));
      setData(arrayToSort);
      setActiveVekt((prev) => !prev);
    }
  }

  function sorterId() {
    if (activeId) {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.idData > b.idData ? -1 : 1));
      setData(arrayToSort);
      setActiveId((prev) => !prev);
    } else {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.idData < b.idData ? -1 : 1));
      setData(arrayToSort);
      setActiveId((prev) => !prev);
    }
  }

  function sorterType() {
    if (activeType) {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.type < b.type ? -1 : 1));
      setData(arrayToSort);
      setActiveType((prev) => !prev);
    } else {
      let arrayToSort = [...data];
      arrayToSort.sort((a, b) => (a.type > b.type ? -1 : 1));
      setData(arrayToSort);
      setActiveType((prev) => !prev);
    }
  }

  function slettMeg(idData) {
    console.log("Sending auth: ", cookies);
    if (window.confirm("Sikkert at du vil slette denne rapporteringen?")) {
      try {
        axios
          .post(`${AWS_URL}/api/slett-rapportering`, {
            data: {
              Credentials: "include",
              mode: "cors",
              cookies: cookies,
              idData: idData,
            },
          })
          // axios.post("http://localhost:3005/api/slett-rapportering",  {data: {Credentials: "include", mode: 'cors', cookies: cookies, idData: idData}})

          .then((res) => {
            console.log("wtf");
            if (res.status === 200) {
              toast.success("Sletting av siste registrerte veiing vellykket!!");
            }

            if (res.status === 401) {
              toast.warn(
                "Ikke autorisert! Hvis du er logget inn har din sesjon utløp. Vennligst logg ut og inn, og prøv igjen."
              );
              console.log(
                "Not authorized!: ",
                res.status,
                "data returned: ",
                res.data
              );
            }
          })

          .then(() => setIsDeleted((prev) => !prev))
          .catch((err) => {
            if (err.response.status === 401) {
              toast.warn(
                "Ikke autorisert! Hvis du er logget inn har din sesjon utløp. Vennligst logg ut og inn."
              );
            }
          });
      } catch (error) {
        console.log("wtf");
        console.log("err ", error);
      }
    } else {
      return;
    }
  }

  return (
    <div className="rapporteringer_container">
      {authenticated && !loading ? (
        <div className="authenticated_show_data_wrapper">
          <h1>Alle rapporteringer</h1>
          {data ? (
            <table>
              <tr className="table_header">
                <th
                  className="table_header_sortering_id"
                  onClick={() => {
                    sorterId();
                  }}
                >
                  <div>
                    <span>id</span> {<BsFilterCircle />}
                  </div>
                </th>
                <th
                  className="table_header_sortering"
                  onClick={() => {
                    sorterType();
                  }}
                >
                  <div>
                    <span>Avfallstype</span> {<BsFilterCircle />}
                  </div>
                </th>
                <th
                  className="table_header_sortering"
                  onClick={() => {
                    sorterVekt();
                  }}
                >
                  <div>
                    <span>Antall kilo</span> {<BsFilterCircle />}
                  </div>
                </th>
                <th
                  className="table_header_sortering"
                  onClick={() => {
                    sorterDato();
                  }}
                >
                  <div>
                    <span>Dato</span> {<BsFilterCircle />}
                  </div>
                </th>
                <th>Slett</th>
              </tr>

              {data.map((element, index) => {
                let newDate = new Date(element.dato).toLocaleDateString(
                  "no-NO",
                  { year: "numeric", month: "2-digit", day: "2-digit" }
                );

                return (
                  <tr key={index}>
                    <td>{element.idData}</td>
                    <td>{element.type}</td>
                    <td>{element.kilo}</td>
                    <td>{newDate}</td>
                    <td>
                      <button onClick={() => slettMeg(element.idData)}>
                        slett meg
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : null}
        </div>
      ) : (
        <span
          style={{
            marginTop: "5rem",
            position: "absolute",
            transform: "translateX(-50%)",
            left: "50%",
          }}
        >
          <ClipLoader color="#1ed9e9" />
        </span>
      )}

      <ToastContainer />
    </div>
  );
}
