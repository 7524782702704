import React from 'react'
import Prosjektet_initial from '../prosjektet_init/Prosjektet_Initial'


export default function Prosjektet() {
    let logo = "https://nxtech.s3.eu-north-1.amazonaws.com/pictures/logo_iamd.png"
    return (
        <div className='page_wrapper'>
            <header>
                <h1 >Innrapportering av Miljøfyrtårndata</h1>
                <h3>Bacheloroppgave Våren 2023</h3>
                <img className='prosjektlogo' src={logo} alt="Logo of the bachelors thesis project" />

                <h4>Andreas Mathisen, Simen. J. Kjærstad & Stian Larsen</h4>
            </header>

            

            <main>

                <Prosjektet_initial logo={logo} />

            </main>
        </div>
    )
}
