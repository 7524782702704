import React from 'react'

export default function StianText() {
    return (
        <>
            <p>
                Dataingeniørstudent med lidenskap for fullstack.
            </p>

            <h3>Favoritt kodespråk</h3>
            <p>Hvis jeg måtte velge, så Javascript. Python kommer på en god andreplass. </p>

            <h3>Hva liker du med studiet?</h3>
            <p>Hmm.. Det er vanskelig å velge. Liker det at jeg får utfordret meg på vanskelige prosjekter i team med andre studenter.</p>


            <h3>Jobbe i team eller alene?</h3>
            <p>På større prosjekter i Team, med ved mindre oppgaver som feks innleveringer liker jeg å jobbe alene. (NB: så lenge jeg kan løse oppgavene selvsagt. Om ikke er jeg glad i å jobbe med Simen *LOL*).</p>

            <h3>Hva syntes du om denne bacheloroppgaven?</h3>
            <p>Jeg syntes det er spennende at jeg får testet meg på både hardware, software og skytjenester. Skytjenester og nettside er noe jeg virkelig vil jobbe med senere. Så det å få muligheten her er jo en spennende måte for meg å få erfaring i det på.</p>
    
    
            <h3>Hva tenker du om oppdragsgiveren NxTech?</h3>
            <p>Hans-Erik som er vår kontaktperson hos NxTech har gitt oss ganske frie tøyler, noe som gjør at vi kan ta styringen rundt valg av teknologier og den slags. Det er utrolig kult at de gir oss den tilliten. De er veldig interesserte i det vi driver med, så det er hyggelig å vite at de syntes det er like kult som oss.</p>
        </>
    )
}
